<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between ">
          <h4>Cadastro de usuário</h4>
          <mat-icon class="fs-5 material-symbols-outlined d-flex"
            matTooltip="Por favor, preencha todos os campos sinalizados com um *" matTooltipPosition="after">
            help
          </mat-icon>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Nome</label><small style="color: red;"> *</small>
                <input name="nome" formControlName="nome" type="text" class="form-control">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">E-mail</label><small style="color: red;"> *</small>
                <input name="email" formControlName="email" type="text" class="form-control">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Telefone</label><small style="color: red;"> *</small>
                <input name="telefone" formControlName="telefone" type="text" class="form-control"
                  mask="(00)00000-0000">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Cargo</label>
                <input name="cargo" formControlName="cargo" type="text" class="form-control">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Empresas</label><small style="color: red;"> *</small>
                <select class="form-select" formControlName="empresa" placeholder="Selecione uma empresa">
                  <option *ngFor="let emp of empresas" [value]="emp.id">
                    {{emp.nome}}
                  </option>
                </select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Permissão</label><small style="color: red;"> *</small>
                <div class="form-control" *ngIf="entity?.id == session.user?.id">
                  <label>{{session.user.nomePermissao}}</label>
                </div>
                <div *ngIf="entity?.id != session.user?.id">
                  <select class="form-select" formControlName="permissao">
                    <option value="" disabled>Selecione uma permissão</option>
                    <option *ngFor="let perm of permissoes" [value]="perm.id">
                      {{perm.nomePermissao}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <br>

          <div *ngIf="queryId != 1 && queryId != 'novo' || entity.id != undefined">
            <div class="card-association" *ngIf="session.user.nomePermissao == 'SETY'">
              <div class="row">
                <h5 class="pt-2 pb-2">Associar Empresa </h5>
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <div>
                    <select [ngStyle]="!!hasError ? {'border': '1px solid red'} : {'border': '1px solid #ced4da'} "
                      class="form-select" [(ngModel)]="empresaAssociada">
                      <option [value]="null" disabled>Associe uma empresa</option>
                      <option *ngFor="let empresa of listEmpresasAssociadas" [value]="empresa.id">
                        {{empresa.nome}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 button-associar">
                  <button [disabled]="!empresaAssociada" class="btn btn-success" (click)="associarEmpresa(empresaAssociada!)"> Confirmar</button>
                </div>
              </div>

              <div class="crud-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Empresas Associadas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let emp of empresasAssociadas">
                      <td>{{emp.empresa.nome}}</td>
                      <td>
                        <button class="btn btn-outline-danger" style="border: none;"
                          (click)="desassociarEmpresa(emp.id)">
                          <span class="material-symbols-outlined">
                            delete
                          </span>
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="!!loading">
                      <td class="d-flex justify-content-center"><mat-spinner></mat-spinner></td>
                    </tr>
                    <tr *ngIf="empresasAssociadas.length <= 0 && !loading">
                      <td>Nenhuma empresa associada</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-center card-footer">
                <dg-pagination (changePage)="changePage($event)" [dataPagination]="pagination"></dg-pagination>
              </div>
            </div>
          </div>

          <app-footer>
            <div class="d-flex justify-content-end">
              <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
              <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
            </div>
          </app-footer>
        </div>
      </div>
    </div>
  </div>

  <div class="loader" *ngIf="!!loading">
    <div class="content-loader">
      <mat-spinner></mat-spinner>
    </div>
  </div>
