<div class="container dg-table">
  <div class="row">
    <div class="card">
      <div class="card-header row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <h4 class="card-title">Lacres Eletrônicos</h4>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-inline-flex justify-content-sm-center justify-content-end">
          <button [disabled]="!permissao.create" class="btn btn-success" routerLink="novo">Adicionar lacre</button>
        </div>
      </div>

      <div class="card-body">

        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <label class="form-label">Nº de Série</label>
            <input type="text" class="form-control" (clearParams)="numSerie = ''" [(ngModel)]="numSerie"
              dg-dynamic-group="lacre" paramName="numeroDeSerie">
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-inline-flex justify-content-center">
            <button class="btn btn-success btn-sm m-1" dynamic-group-control="lacre" filter>Pesquisar</button>
            <button class="btn btn-secondary btn-sm m-1" dynamic-group-control="lacre" clear>Limpar</button>
          </div>
        </div>
      </div>

      <div class="card-body">

        <dg-table #tableHtml classes="crud-table" dg-dynamic-table [dynamicTable]="tableCustom">
          <ng-template dgTableColumn columnName="posicionamentoAtual" let-data>
            <a [routerLink]="['/user','monitoramento', data.row.imei]"
              [queryParams]="{size: tableCustom.controls.pagination.get().size, page: tableCustom.controls.pagination.get().page}">Monitoramento</a>
          </ng-template>
          <ng-template dgTableColumn columnName="status" let-data>
            {{data.row.status.titulo.replaceAll('_', ' ') }}
          </ng-template>
          <ng-template dgTableColumn columnName="travado" let-data>
            <span *ngIf="!data.row.violado">
              {{!!data.row.travado ? 'TRAVADO' : 'ABERTO'}} / {{!!data.row.lacrado? 'LACRADO' : 'DESLACRADO'}}
            </span>
            <span *ngIf="!!data.row.violado">
              Violado
            </span>
          </ng-template>
          <ng-template dgTableColumn columnName="comunicacao" let-data>
            {{!!data.row.comunicacao ? data.row.comunicacao : '--'}}
          </ng-template>
          <ng-template dgTableColumn columnName="bateria" let-data>
            {{!!data.row.bateria ? data.row.bateria : '--'}}
          </ng-template>
          <ng-template dgTableColumn columnName="empresa" let-data>
            {{!!data.row.empresa?.nome ? data.row.empresa?.nome : '--'}}
          </ng-template>

        </dg-table>

      </div>
      <div class="pt-1 d-flex justify-content-center card-footer">

        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableCustom" ></dg-paginator>
      </div>

    </div>
    <div class="loader" *ngIf="loading">
      <div class="content-loader">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>


  <app-footer></app-footer>
