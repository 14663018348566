import { MonitoramentoMainComponent } from './monitoramento/monitoramento-main/monitoramento-main.component';
import { ViagensInserEditComponent } from './viagens/viagens-inser-edit/viagens-inser-edit.component';
import { ViagensMainComponent } from './viagens/viagens-main/viagens-main.component';
import { SimcardInserEditComponent } from './simcard/simcard-inser-edit/simcard-inser-edit.component';
import { SimcardMainComponent } from './simcard/simcard-main/simcard-main.component';
import { MotoristasInserEditComponent } from './motoristas/motoristas-inser-edit/motoristas-inser-edit.component';
import { MotoristasMainComponent } from './motoristas/motoristas-main/motoristas-main.component';
import { CaminhoesInserEditComponent } from './caminhoes/caminhoes-inser-edit/caminhoes-inser-edit.component';
import { CaminhoesMainComponent } from './caminhoes/caminhoes-main/caminhoes-main.component';
import { LacresInserEditComponent } from './lacres/lacres-inser-edit/lacres-inser-edit.component';
import { LacresMainComponent } from './lacres/lacres-main/lacres-main.component';
import { UsuarioInserEditComponent } from './usuario/usuario-inser-edit/usuario-inser-edit.component';
import { UsuarioMainComponent } from "./usuario/usuario-main/usuario-main.component";
import { EmpresaInserEditComponent } from "./empresa/empresa-inser-edit/empresa-inser-edit.component";
import { EmpresaMainComponent } from "./empresa/empresa-main/empresa-main.component";
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RotasMainComponent } from './rotas/rotas-main/rotas-main.component';
import { RotasInserEditComponent } from './rotas/rotas-inser-edit/rotas-inser-edit.component';
import { PermissoesMainComponent } from './permissoes/permissoes-main/permissoes-main.component';
import { PermissoesInsertComponent } from './permissoes/permissoes-insert/permissoes-insert.component';
import { DashboardMainComponent } from './dashboard/dashboard-main/dashboard-main.component';
import { ContatosMainComponent } from './contatos/contatos-main/contatos-main.component';

const menu = JSON.parse(String(window.localStorage.getItem('menu')));

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardMainComponent
  },
  {
    path: 'empresas',
    component: EmpresaMainComponent
  },
  {
    path: 'empresas',
    children: [
      { path: ':id', component: EmpresaInserEditComponent }
    ]
  },
  {
    path: 'usuarios',
    component: UsuarioMainComponent
  },
  {
    path: 'usuarios',
    children: [
      { path: ':id', component: UsuarioInserEditComponent }
    ]
  },
  {
    path: 'lacres',
    component: LacresMainComponent
  },
  {
    path: 'lacres',
    children: [
      { path: ':id', component: LacresInserEditComponent }
    ]
  },

  {
    path: 'caminhoes',
    component: CaminhoesMainComponent
  },
  {
    path: 'caminhoes',
    children: [
      { path: ':id', component: CaminhoesInserEditComponent }
    ]
  },
  {
    path: 'motoristas',
    component: MotoristasMainComponent
  },
  {
    path: 'motoristas',
    children: [
      { path: ':id', component: MotoristasInserEditComponent }
    ]
  },
  {
    path: 'simcard',
    component: SimcardMainComponent
  },
  {
    path: 'simcard',
    children: [
      { path: ':id', component: SimcardInserEditComponent }
    ]
  },
  {
    path: 'viagens',
    component: ViagensMainComponent
  },
  {
    path: 'viagens',
    children: [
      { path: ':id', component: ViagensInserEditComponent }
    ]
  },
  {
    path: 'monitoramento',
    component: MonitoramentoMainComponent
  },
  {
    path: 'monitoramento',
    children: [
      { path: ':id', component: MonitoramentoMainComponent }
    ]
  },
  {
    path: 'rotas',
    component: RotasMainComponent
  },
  // {
  //   path: 'logs',
  //   component: LogsComponent
  // },
  {
    path: 'rotas',
    children: [
      { path: ':id', component: RotasInserEditComponent }
    ]
  },
  {
    path: 'permissoes',
    component: PermissoesMainComponent
  },
  {
    path: 'permissoes',
    children: [
      { path: ':id', component: PermissoesInsertComponent }
    ]
  },
  {
    path: 'contatos',
    component: ContatosMainComponent
  },

  {
    path: '**',
    redirectTo: `${!!menu ? menu[0]?.url : ''}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
