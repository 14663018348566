import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  QueryList,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as L from 'leaflet';
import { MonitoramentoService } from '../service/monitoramento.service';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { SessionService } from 'src/app/core/services/session/session.service';
import { InterfPagination } from '@datagrupo/dg-crud';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { CdkDynamicTable, CdkDynamicTableService } from '@datagrupo/dg-ng-util';
import {
  LacreAlarmDTO,
  LacreEventDTO,
  lacreInterf,
} from './monitoramento-main.types';
import { LacresEntity } from '../../entitys/lacres.entity';

@Component({
  selector: 'app-monitoramento-main',
  templateUrl: './monitoramento-main.component.html',
  styleUrls: ['./monitoramento-main.component.scss'],
})
export class MonitoramentoMainComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('closeModal') closeModal!: ElementRef;

  public aa = '';

  loading: boolean = false;
  msg: string = '';
  senhaGerada: string = '';

  pagination: InterfPagination = {
    page: 0,
    size: 10,
    sort: 'id,desc',
  };

  truckIcon = L.divIcon({
    className: 'custom-div-icon',
    html: "<div class='marker-pin'></div><i class='material-icons'>local_shipping</i>",
    iconSize: [30, 42],
    iconAnchor: [0, 0],
  });

  public map!: L.Map;
  currentPopup?: L.Marker<L.DivIcon>;
  lacre: lacreInterf[] = [];

  listaLacres: lacreInterf[] = [];

  lacreId: string = '';
  routeParams: string = '';
  web: WebSocket | undefined;
  uuid: string = uuidv4();
  marker: any;
  modalData: lacreInterf[] = [];

  assinatura: string = '';
  timeout: any = '';
  senha = new FormControl('', [Validators.required]);
  senhaManual = new FormControl('', [Validators.required]);

  hasError: boolean = false;

  tableLacre: CdkDynamicTable.tableClass;

  lacreOpen: boolean = false;

  constructor(
    private webSocketService: MonitoramentoService,
    public route: ActivatedRoute,
    private http: GenericHttpService,
    private session: SessionService,
    private snackBar: MatSnackBar,
    private createTable: CdkDynamicTableService
  ) {
    webSocketService.connect(session.token.getToken());
    this.tableLacre = createTable.create('array', {
      columns: [{ headerName: 'lacres', name: 'imei' }],
      filters: {
        group: 'lacre',
        reactive: true,
        filters: {
          imei: {
            findFunc: (val) => {
              return { imei: val };
            },
          },
        },
      },
    });
  }

  ngOnInit(): void {
    //Metodo desenvolvido para o retorno dos valores do lacre após o pedido de abertura
    this.webSocketService.socket.on('actionResponse', (resp) => {
      this.msg = resp;
      this.loading = false;
      let fakeId = JSON.parse(String(sessionStorage.getItem('fakeId')));
      if (fakeId) {
        if (this.assinatura != 'DIGITAL' && this.senhaGerada != '') {
          Swal.fire({
            title: 'Senha autorizada',
            text: `Envie essa senha: ${this.senhaGerada} para o responsável do lacre ativo`,
            icon: 'success',
            confirmButtonColor: 'blue',
          });
        } else {
          Swal.fire({
            title: 'Senha autorizada',
            text: `Caminhão desbloqueado, por favor verifique com o responsável pelo caminhão`,
            icon: 'success',
            confirmButtonColor: 'blue',
          });
        }
        if (this.modalData[0].p[0].plock == 'aberto') {
          this.snackBar.open(
            `O lacre ${this.modalData[0].imei} foi destravado com sucesso`,
            'X',
            {
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              duration: 5000,
            }
          );
        }
        if (this.modalData[0].travado) {
          Swal.fire({
            title: 'Travado com sucesso',
            icon: 'success',
            confirmButtonColor: 'blue',
          });
        }
        if (this.modalData[0].lacrado) {
          Swal.fire({
            title: 'Lacrado com sucesso',
            icon: 'success',
            confirmButtonColor: 'blue',
          });
        }
      }
      clearTimeout(this.timeout);
      sessionStorage.removeItem('fakeId');
      return;
    });
    this.getLacres();
    this.checkEvent();

    this.route.params.subscribe((resp) => {
      this.routeParams = resp['id'];
    });
    this.route.queryParams.subscribe((res) => {
      this.pagination = res;
    });
  }

  //Metodo para acontecer quando mudar a tela e encerrar a conexão
  ngOnDestroy(): void {
    if (this.lacreId != '' || this.routeParams != undefined) {
      this.webSocketService.disconect();
    }
  }

  //Usado para montar a tabela de lacres
  getLacres() {
    this.loading = true;
    this.http.get('lacre', {}).subscribe(
      (resp) => {
        this.loading = false;
        this.getChecked(resp.data);
      },
      () => {
        this.loading = false;
        Swal.fire({
          title: 'Erro',
          text: 'Ocorreu um erro na busca dos dados, por favor atualize a página.',
          icon: 'error',
          confirmButtonColor: 'blue',
        });
      }
    );
  }

  //Verifica se o lacre está sendo observado e se estiver põe como true
  getChecked(val: any) {
    this.listaLacres = val;
    this.findLacre();
    if (this.lacre.length > 0) {
      this.listaLacres.forEach((v) => {
        this.lacre.forEach((val) => {
          if (v.imei == val?.id) {
            (v.isChecked = true), (v.hasError = false);
          }
        });
      });
    }
    this.tableLacre.controls.data.set(this.listaLacres);
    this.tableLacre.find();
  }

  findLacre() {
    let data = this.listaLacres.find((v) => v.imei == this.routeParams);
    const index = this.listaLacres.findIndex((v) => v.imei == data?.imei);
    this.routeConectSocket(data, index);
  }

  //Conexão com o lacre escolido
  getSocket(event: any) {
    const idLacre: string = event.target.value;
    let index = this.listaLacres.findIndex((v) => v.imei == idLacre);
    this.listaLacres[index].isChecked = event.target.checked;
    if (!!idLacre && !!event.target.checked) {
      this.lacreId = idLacre;
      this.loading = true;
      this.webSocketService.socket.on(idLacre, (resp: any) => {
        const lacreIdx = this.lacre.findIndex((v) => v.id == resp.id);
        this.listaLacres[index].p = {
          ...resp.p,
        };
        this.checkPoint(resp, lacreIdx, this.listaLacres[index]);
        if (this.listaLacres[index].p) {
          clearTimeout(lacreTimeout);
        }
      });
      let lacreTimeout = setTimeout(() => {
        this.webSocketService.socket.off(idLacre)
        this.loading = false;
        //   Swal.fire({
        //     title: 'Erro ao conectar',
        //     text: 'Ocorreu um erro ao conectar com o lacre, entre em contato com a central',
        //     icon: 'error',
        //   });
      }, 10000);

      this.webSocketService.getMonitor(this.lacreId);
    }
    if (!this.listaLacres[index].isChecked) {
      this.webSocketService.socket.off(idLacre, () => { });
      this.webSocketService.socket.off('lacreEvent');
      this.webSocketService.socket.off('lacreAlarm');
      this.destroyPoint(this.listaLacres[index].imei);
    }
  }

  //Conexão via parâmetros da url
  routeConectSocket(data: any, index: number) {
    if (data != undefined) {
      this.listaLacres[index].isChecked = true;
      if (this.listaLacres.length > 10) {
        this.tableLacre.controls.pagination.set({
          size: this.pagination.size,
          page: this.pagination.page,
        });
      }
      this.lacreId = data.imei;
      this.webSocketService.socket.on(data.imei, (res: any) => {
        const lacreIdx = this.lacre.findIndex((v) => v.id == res.id);
        this.listaLacres[index].p = {
          ...res.p,
        };
        this.checkPoint(res, lacreIdx, data);
      });
      this.webSocketService.getMonitor(data.imei);
      if (!this.listaLacres[index].isChecked) {
        this.webSocketService.socket.off(data.imei);
        this.webSocketService.socket.off('lacreEvent');
        this.webSocketService.socket.off('lacreAlarm');
        this.destroyPoint(this.listaLacres[index].imei);
      }
    }
  }

  //Ação para monitorar o lacre
  socketAction() {
    this.webSocketService.getMonitor(this.uuid);
  }

  //Inicialização do mapa de monitoramento
  private initMap(): void {
    const bounds: [number, number] = [-23.215384, -45.906797];

    this.map = L.map('map').setView(bounds, 4);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution:
        '&copy; <a hrfe="http://www.openstreetmap.org/copy/copyright">OpenStreetMap</a>',
    }).addTo(this.map);
  }

  //Sera executado depois que o ngOnit for iniciado
  ngAfterViewInit(): void {
    this.initMap();
  }

  checkEvent() {
    this.webSocketService.socket.on('lacreAlarm', (resp: LacreAlarmDTO) => {
      if (
        !!resp.info.isLockNormalOrTampered ||
        !!resp.info.isShacklePulledOut
      ) {
        this.messageViolation(resp.id);
      } else if (!!resp.info.isMovingWhileOpen) {
        Swal.fire({
          title: `ATENÇÃO VERIFIQUE O LACRE ${resp.id}`,
          text: `Caminhão em movimento, o lacre ainda está destravado`,
          icon: 'error',
          confirmButtonColor: 'red',
        });
      }
    });
  }

  messageViolation(imei: string) {
    Swal.fire({
      title: `ATENÇÃO VERIFIQUE O LACRE ${imei}`,
      text: `O lacre está sendo violado`,
      icon: 'error',
      confirmButtonColor: 'red',
    });
  }

  //Verifica se o lacre já está no mapa
  checkPoint(val: lacreInterf, lacreIdx: number, lacre?: any) {
    if (lacreIdx == -1) {
      this.lacre.push({
        ...val,
        truckIcon: undefined,
        isChecked: true,
        hasError: false,
      });
      this.createOrUpdatePoint(val, lacre);
      return;
    }
    if (this.lacre.length > 0) {
      this.createOrUpdatePoint(val, lacre, lacreIdx);
      return;
    }
  }

  //Cria o marcador do caminhão no mapa e atualiza o marcador
  createOrUpdatePoint(val: lacreInterf, lacre?: LacresEntity, index?: number) {


    if (index == undefined) {
      let fakeId = this.lacre.findIndex((v) => v.id == val.id);
      if (
        fakeId != undefined &&
        this.lacre[fakeId]?.truckIcon == undefined &&
        this.lacre[fakeId]?.isChecked
      ) {
        let numSerie = this.tableLacre.controls.data.dataSource.find(
          (v: any) => {
            return v;
          }
        );
        this.lacre[fakeId] = {
          ...this.lacre[fakeId],
          numeroDeSerie: lacre?.numeroDeSerie ?? numSerie?.numeroDeSerie,
        };
        this.lacre[fakeId].truckIcon = L.marker(
          [
            Number(this.lacre[fakeId].p[0].lt),
            Number(this.lacre[fakeId].p[0].lg),
          ],
          { icon: this.truckIcon }
        )
          .on('click', () => {
            if (lacre) {
              this.setDataModal(lacre);

            }
          })
          .addTo(this.map)
          .bindPopup(this.getPopupMarkup(this.lacre[fakeId]));
      }
    } else {
      this.lacre[index] = {
        ...this.lacre[index],
        p: val.p,
        online: val.online,
      };
      this.lacre[index].truckIcon
        ?.setLatLng([
          Number(this.lacre[index].p[0].lt),
          Number(this.lacre[index].p[0].lg),
        ])
        .addTo(this.map)
        .setPopupContent(this.getPopupMarkup(this.lacre[index]));
      if (this.lacre[index].p[0].tds == 'violado') {
        this.messageViolation(this.lacre[index].imei);
      }
      if (this.lacre[index].p[0].elock == 'aberto' && this.lacreOpen == false && this.lacre[index].online) {
        this.snackBar.open(
          `O lacre ${this.lacre[index].imei} foi aberto com sucesso`,
          'X',
          {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            duration: 5000,
          }
        );
        this.lacreOpen = true;
      } else if (this.lacre[index].p[0].elock != 'aberto') {
        this.lacreOpen = false;
      }
    }
    this.loading = false;
  }

  //Apaga o marcador do caminhão no mapa
  destroyPoint(id: string | number) {
    let index = this.lacre.findIndex((v) => v.id == id);
    if (index != -1) {
      if (this.lacre[index].truckIcon != undefined) {
        this.currentPopup?.closePopup();
        this.map.removeLayer(this.lacre[index].truckIcon!);
        this.lacre[index].truckIcon = undefined;
        this.disconectDevice(this.lacre[index]?.id + '');
        this.lacre.splice(index, 1);
      }
    }
  }

  //Desconecta o lacre selecionado
  disconectDevice(id: string) {
    this.webSocketService.disconectOne(id);
  }

  //Popula os valores no modal
  setDataModal(data: lacreInterf) {
    this.http.get(`lacre/${data.id}`).subscribe((res) => {
      data = { ...data, viagemAtual: res.viagemAtual };
      this.modalData = [];
      this.modalData.push(data);
    });
  }

  //Metodo para envio das ações de abrir lacre manual e lacre digital
  sendPassword(signature: string) {
    if (
      this.senha.value == '' ||
      this.senha.value == null ||
      this.senha.value == undefined
    ) {
      this.senha.markAllAsTouched();
      alert('Por favor, preencha a senha de acesso.');
      return;
    }

    this.http
      .post('/auth/monitoramento/auth', {
        senha: this.senha.value,
        id: this.session.user?.id,
      })
      .subscribe(
        (resp) => {
          if (resp) {
            if (signature == 'DIGITAL') {
              this.close();
              this.sendAction(this.modalData[0], 'abrir');
            } else {
              this.close();
              this.sendAction(this.modalData[0], 'senha', this.senhaGerada);
              this.webSocketService.socket.once(
                'queryResponse',
                ({ dynamicPassword = '', staticPassword = '' } = {}) => {
                  this.loading = false;
                  clearTimeout(this.timeout);
                  if (dynamicPassword) {
                    this.senhaGerada = dynamicPassword;
                  } else {
                    this.senhaGerada = staticPassword;
                  }
                  if (this.senhaGerada != '') {
                    Swal.fire({
                      title: 'Senha autorizada',
                      text: `Envie essa senha: ${this.senhaGerada} para o responsável do lacre ativo`,
                      icon: 'success',
                      confirmButtonColor: 'blue',
                    });
                    this.senha.reset();
                  }
                }
              );
            }
          }
          this.senha.reset();
        },
        () => {
          Swal.fire({
            title: 'Error',
            text: 'Senha incorreta, por favor verifique a senha digitada',
            icon: 'error',
            confirmButtonColor: 'blue',
          });
        }
      );
  }

  //Fecha o modal
  close() {
    this.closeModal.nativeElement.click();
    this.senha.reset();
  }

  //Inicializa o loading
  checkLoader() {
    this.loading = true;
    this.timeout = setTimeout(() => {
      this.loading = false;
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro na comunicação com o lacre, por favor tente novamente em alguns segundos.',
        icon: 'error',
        confirmButtonColor: 'blue',
      });
    }, 30000);
  }

  //Envia a ação para o lacre
  sendAction(dataModal: lacreInterf, actionLacre: string, senhaLacre?: string) {
    sessionStorage.setItem('fakeId', JSON.stringify(dataModal.id));
    const data = {
      imei: dataModal.imei,
      action: actionLacre,
      password: senhaLacre,
    };
    this.webSocketService.sendAction(data);
    this.checkLoader();
  }

  //Gera o html do popup
  private getPopupMarkup(lacreInfo: lacreInterf): L.Content {
    return `

              <div>
                <span>Nº Lacre: ${lacreInfo.numeroDeSerie}</span>
              <div>
              <div class="row">
                <span>Status eletrônico: ${lacreInfo.p[0]?.elock}</span>
                <span>Status físico: ${lacreInfo.p[0]?.plock}</span>
                <span>Online: ${!!lacreInfo.online ? 'Sim' : 'Não'}</span>
              </div>
              <div>
              <br>
              <span>Última Localização</span>
                <ul style="list-style: none;">
                  <li>
                    <span>Latitude: ${lacreInfo.p[0]?.lt}</span>
                  </li>
                  <li>
                    <span>Longitude: ${lacreInfo.p[0]?.lg}</span>
                  </li>
                  <br>
                  <li>
                    <span>Velocidade: ${lacreInfo.p[0]?.sp} km/h</span>
                  </li>
                  <li>
                    <span>Bateria: ${lacreInfo.p[0]?.b}%</span>
                  </li>
                  <li>
                    <span>Data: ${lacreInfo.p[0]?.t}</span>
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" id="button-submit" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Detalhes da Viagem
                </button>
              </div>
              `;
  }
}
export { lacreInterf };
